import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const TranquesChile = lazy(() => import('./components/tranqueschile'));

function App() {
  return (
    <Router>
      <div className="App">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<TranquesChile />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;